export const linkedinBlogPostLink = {
  id: 'linkedin-blog-post-link',
  value: [1200, 627],
  label: 'Blog Post Link'
};
export const linkedinPageCover = {
  id: 'linkedin-page-cover',
  value: [1128, 191],
  label: 'Page Cover'
};
export const linkedinSponsoredContent = {
  id: 'linkedin-sponsored-content',
  value: [1200, 657],
  label: 'Sponsored Content Image'
};
export const linkedinSponsoredCarousel = {
  id: 'linkedin-sponsored-carousel',
  value: [1080, 1080],
  label: 'Sponsored Carousel Image'
};
export const linkedin = {
  id: 'linkedin',
  name: 'LinkedIn',
  items: [linkedinBlogPostLink, linkedinPageCover, linkedinSponsoredContent, linkedinSponsoredCarousel]
};