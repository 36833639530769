export const instagramStory = {
  id: 'instagram-story',
  value: [1080, 1920],
  label: 'Story / Reel Video'
};
export const instagramLandscape = {
  id: 'instagram-landscape',
  value: [1080, 566],
  label: 'Landscape Feed/Ad'
};
export const instagramPortrait = {
  id: 'instagram-portrait',
  value: [1080, 1350],
  label: 'Portrait'
};
export const instagramSquare = {
  id: 'instagram-square',
  value: [1080, 1080],
  label: 'Square Feed/Ad'
};
export const instagram = {
  id: 'instagram',
  name: 'Instagram',
  items: [instagramStory, instagramLandscape, instagramPortrait, instagramSquare]
};