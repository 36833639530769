export const facebookStory = {
  id: 'facebook-story',
  value: [1080, 1920],
  label: 'Story'
};
export const facebookPost = {
  id: 'facebook-post',
  value: [1200, 630],
  label: 'Post & Timeline Photo'
};
export const facebookCover = {
  id: 'facebook-cover',
  value: [851, 315],
  label: 'Cover Photo'
};
export const facebook = {
  id: 'facebook',
  name: 'Facebook',
  items: [facebookStory, facebookPost, facebookCover]
};