export const paperA4 = {
  id: 'paper-a4',
  value: [2598, 3626],
  label: 'A4'
};
export const paperLetter = {
  id: 'paper-letter',
  value: [2669, 3413],
  label: 'Letter'
};
export const paperLegal = {
  id: 'paper-legal',
  value: [2669, 4323],
  label: 'Legal'
};
export const paperTabloid = {
  id: 'paper-sponsored-carousel',
  value: [3413, 5220],
  label: 'Tabloid'
};
export const paper = {
  id: 'paper',
  name: 'Paper',
  items: [paperA4, paperLetter, paperLegal, paperTabloid]
};