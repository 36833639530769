export const iphone14Pro = {
  id: 'iphone-14-pro',
  value: [393, 852],
  label: 'iPhone 14 Pro'
};
export const iphone14 = {
  id: 'iphone-14',
  value: [390, 844],
  label: 'iPhone 14'
};
export const iphone13 = {
  id: 'iphone-13',
  value: [375, 812],
  label: 'iPhone 13'
};
export const iphoneSE = {
  id: 'iphone-se',
  value: [320, 568],
  label: 'iPhone SE'
};
export const galaxyS10 = {
  id: 'galaxy-s10',
  value: [360, 760],
  label: 'Galaxy S10'
};
export const galaxyA50 = {
  id: 'galaxy-a50',
  value: [412, 892],
  label: 'Galaxy A50'
};
export const ipadMini8_3 = {
  id: 'ipad-mini-8.3',
  value: [744, 1133],
  label: 'iPad mini 8.3'
};
export const ipadPro11 = {
  id: 'ipad-pro-11',
  value: [834, 1194],
  label: 'iPad Pro 11"'
};
export const surfacePro8 = {
  id: 'surface-pro-8',
  value: [1440, 960],
  label: 'Surface Pro 8'
};
export const mobileDevices = {
  id: 'mobile-devices',
  name: 'Mobile Devices',
  items: [iphone14Pro, iphone14, iphone13, iphoneSE, galaxyS10, galaxyA50, ipadMini8_3, ipadPro11, surfacePro8]
};