export const pinterestPin = {
  id: 'pinterest-pin',
  value: [1000, 1500],
  label: 'Pin'
};
export const pinterestSquarePin = {
  id: 'pinterest-square-pin',
  value: [1000, 1000],
  label: 'Square Pin'
};
export const pinterestStoryPin = {
  id: 'pinterest-story-pin',
  value: [1080, 1920],
  label: 'Story Pin'
};
export const pinterestFleet = {
  id: 'pinterest-fleet',
  value: [1080, 1920],
  label: 'Fleet'
};
export const pinterestCollectionPin = {
  id: 'pinterest-collection-pin',
  value: [1000, 1500],
  label: 'Collection Pin'
};
export const pinterestCollectionSquarePin = {
  id: 'pinterest-collection-square-pin',
  value: [1000, 1000],
  label: 'Collection Square Pin'
};
export const pinterestShoppingAd = {
  id: 'pinterest-shopping-ad',
  value: [1000, 1500],
  label: 'Shopping Ad'
};
export const pinterestCarouselPinsAndAds = {
  id: 'pinterest-carousel-pin-ads',
  value: [1000, 1500],
  label: 'Carousel Pins & Ads'
};
export const pinterestProfileCoverPhoto = {
  id: 'pinterest-cover-photo',
  value: [800, 450],
  label: 'Profile Cover Photo'
};
export const pinterest = {
  id: 'pinterest',
  name: 'Pinterest',
  items: [pinterestPin, pinterestSquarePin, pinterestStoryPin, pinterestFleet, pinterestCollectionPin, pinterestCollectionSquarePin, pinterestShoppingAd, pinterestCarouselPinsAndAds, pinterestProfileCoverPhoto]
};