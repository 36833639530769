export const macbookAir = {
  id: 'macbook-air',
  value: [1280, 832],
  label: 'Macbook Air'
};
export const macbookPro14 = {
  id: 'macbook-pro-14',
  value: [1512, 982],
  label: 'Macbook Pro 14"'
};
export const macbookPro16 = {
  id: 'macbook-pro-16',
  value: [1728, 1117],
  label: 'Macbook Pro 16"'
};
export const smallDesktop = {
  id: 'small-desktop',
  value: [1024, 640],
  label: 'Small Desktop'
};
export const desktopDevices = {
  id: 'desktop-devices',
  name: 'Desktop Devices',
  items: [macbookAir, macbookPro14, macbookPro16, smallDesktop]
};