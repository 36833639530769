export const productHuntGallery = {
  id: 'product-hunt-gallery',
  value: [1270, 760],
  label: 'Gallery'
};
export const productHuntThumbnail = {
  id: 'product-hunt-thumbnail',
  value: [240, 240],
  label: 'Thumbnail'
};
export const productHunt = {
  id: 'product-hunt',
  name: 'Product Hunt',
  items: [productHuntGallery, productHuntThumbnail]
};