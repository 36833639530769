import envConfig from '@/config/environment'; // TODO throw errors when price id
// environment variables are unset

const MAX_FREE_WIDGETS = 3;
const MAX_STARTER_WIDGETS = 10;
const MAX_GROWTH_WIDGETS = 20;
export const FREE_SEAT = {
  nickname: 'free',
  price: 0,
  title: 'Free',
  description: `For personal use only with limited features and support`,
  viewLimit: 1000,
  usps: [`${MAX_FREE_WIDGETS} Widgets`, 'Template Database', 'Basic Chat Support'],
  permissions: {
    canCopyWidgetSnippet: true
  }
};
export const STARTER_SEAT = {
  nickname: 'starter',
  price: 20,
  priceId: process.env.NEXT_PUBLIC_STRIPE_STARTER_PRICE_ID || '',
  description: `For small businesses with all the features and support`,
  title: 'Starter',
  viewLimit: 10000,
  usps: [`${MAX_STARTER_WIDGETS} Widgets`, 'Template Database', 'Priority Chat Support', 'Remove Excelkits Logo', 'Google Sheet Sync']
};
export const GROWTH_SEAT = {
  nickname: 'growth',
  price: 40,
  priceId: process.env.NEXT_PUBLIC_STRIPE_GROWTH_PRICE_ID || '',
  description: 'For teams and organizations with advanced content needs and support',
  title: 'Growth',
  viewLimit: 100000,
  usps: [`${MAX_GROWTH_WIDGETS} Widgets`, 'Template Database', 'Priority Chat Support', 'Remove Excelkits Logo', 'Google Sheet Sync', 'Project support']
};
export const ENTERPRISE_SEAT = {
  nickname: 'enterprise',
  priceId: 'TODO',
  viewLimit: Infinity,
  description: 'For large companies with custom features and support and a dedicated account manager',
  title: 'Enterprise',
  usps: ['Unlimited Widgets', 'Template Database', 'Priority Chat Support', 'Remove Excelkits Logo', 'Google Sheet Sync', 'Project support']
};
export const seats = [FREE_SEAT, STARTER_SEAT, GROWTH_SEAT, ENTERPRISE_SEAT];
export const products = [STARTER_SEAT, GROWTH_SEAT];
export const advertising = {
  active: envConfig.isProduction && false
};
export default {
  seats,
  products,
  advertising,
  maximumFreeWidgets: MAX_FREE_WIDGETS
};